$section-highlight-color: $color-white;

.p-project {
    .section {
        display: flex;
        padding: $pad-normal 0;

        @media all and (min-width: $bp-med) {
            padding: $pad-normal 0.7rem;
        }
    }

    .caption-container {
        margin-top: auto;
        max-width: 25rem;

        @media screen and (max-width: 700px) {
            display: none;
        }
    }

    .caption {
        &__title {
            font-size: $font-size-sm-2;
        }

        &__text {
            font-size: $font-size-sm;
        }
    }
}
