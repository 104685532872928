/*------------------------------------*\
    #MIXINS
\*------------------------------------*/
@mixin headingUnderline($color: $h1-border-color) {
    position: relative;

    &::after {
        content: "";
        display: block;
        width: 2rem;
        height: 0.5rem;
        background: $color;
        position: absolute;
        left: -0.25rem;
        bottom: -0.05rem;
        z-index: -1;
    }
}

@mixin backgroundPattern($pattern: '../assets/images/texture.svg', $size: 0.5rem) {
    position: relative;

    &::after {
        content: "";
        background-image: url($pattern);
        background-size: $size;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        opacity: 0.85;
    }
}

@mixin focus-outline() {
    outline: none;
    box-shadow: 0 0 0 2px rgba(blue, 0.3);
}

@keyframes gradientAnimation {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

@mixin antialiasing() {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}
