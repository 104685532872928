/*------------------------------------*\
    #LISTS
\*------------------------------------*/

/**
 * 1) List base styles
 */

 /**
  * Remove list styles from unordered and ordered lists
  */
ol, ul {
	list-style: none;
}

ul {  
    font: $body-font;
    margin-bottom: $space;
}

ul li {
    margin-left: 1rem;
    text-indent: -0.7rem;
    margin-bottom: $pad-normal;

    &::before {
        content: '•' ' ';
        // margin-bottom: -4px;
        // font-size: $font-size-large-2;
        color: $color-muted-lilac-dark;
    }
}

ol {  
  counter-reset: custom-counter;
}

ol li {
    counter-increment: custom-counter;
    margin-left: 1rem;
    margin-bottom: $pad-normal;
    text-indent: -1rem;
}

ol li::before {
    content: counter(custom-counter) '. ';
    color: $color-muted-lilac-dark;
}

/**
 * 2) Definition list base styles
 */

 /**
  * Remove default styling
  */
dd {
    margin-inline-start: 0;
}
