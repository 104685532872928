/*------------------------------------*\
    #PROJECT VIDEOS
\*------------------------------------*/

.project-video {
    line-height: 0;
    border-radius: $border-radius-big;
    overflow: hidden;

    > video {
        width: 100%;

        &:focus {
            @include focus-outline();
        }

        &:focus:not(:focus-visible) {
            box-shadow: none;
        }

        &:focus-visible {
            @include focus-outline();
        }
    }

    &__caption {
        font-size: $font-size-tiny;
        text-align: center;
        margin-top: $pad-medium;
    }
}
