/*------------------------------------*\
    #PAGINATION
\*------------------------------------*/
/**
 * 1) Container that consists of of a page header title and description
 */

/**
 * Page header title
 */
.c-pagination {
    border-top: 1px solid $color-gray;
    margin: $pad-comfortable auto;
    padding: $pad-normal 0;
    max-width: $l-max-width;

    &__section-title {
        font-size: $font-size-sm-2;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: $font-weight-semibold;
    }

    &__buttons-container {
        @media screen and (min-width: $bp-large) {
            display: flex;
            align-items: flex-start;

            > .c-pagination__button {
                flex: 0 0 50%;
            }
        }
    }


    @media screen and (min-width: $bp-large) {
        &__button--previous {
            margin-right: $pad-normal;
        }

        &__button--next {
            margin-left: $pad-normal;
        }
    }

    &__button {
        color: $color-gray-medium;
        position: relative;
        margin-bottom: $pad-large;
        display: block;

        &:hover {
            .c-pagination__action-text {
                opacity: 1;
            }
        }

        &:active {
            &::after {
                background: $color-white;
            }
        }
    }

    &__button-text {
        @media screen and (min-width: $bp-med) {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
        }
    }

    &__button-icon {
        color: $color-gray-medium;
    }

    &__image-container {
        border-radius: $border-radius-big;
        border: 1px solid $color-gray-light;
        position: relative;
        overflow: hidden;
        flex: 0 0 auto;
        position: relative;

        @media screen and (max-width: $bp-med ) {
            margin-bottom: $pad-normal;
        }

        &:after {
            content: '';
            display: block;
            padding-bottom: 50%;
        }
    }

    &__image {
        display: block;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
    }

    &__image--cropped {
        width: 100%;
        left: 60%;
        bottom: -20%;
    }

    &__title {
        font-size: $font-size-large;
        font-weight: $font-weight-bold;
        padding-bottom: 0; 
    }

    &__action-text {
        font-size: $font-size-sm;
        font-weight: $font-weight-semibold;
        text-transform: uppercase;
        letter-spacing: 1px;
        transition: opacity $anim-fast ease-out;
        
        @media screen and (min-width: $bp-med ) {
            opacity: 0;
        }
    }
}
