/*------------------------------------*\
    #BREADCRUMBS
\*------------------------------------*/

/**
 * 1) This is an ordered list showing what level you are on in the site
 */
.c-breadcrumbs {
	display: flex; /* 2 */
	flex-wrap: wrap; /* 3 */
	margin: 0 0 1rem 0; /* 4 */
}

/**
 * Breadcrumbs list item
 * 1) Set to position relative so :after is positioned relative to the breadcrumbs item
 */
.c-breadcrumbs__item {
	position: relative; /* 1 */
	margin-right: 1rem;

	/**
	 * Icon in between list item
	 * 1) Set to position absolute so it is positioned relative to its parent container
	 */
	&:after {
		content:"\203A";
		position: absolute; /* 1 */
		top: 0;
		right: -10px;
	}

	/**
	 * Last breadcrumbs list item
	 */
	&:last-child {
		margin-right: 0;

		/**
		 * Remove last breadcrumb item icon after
		 */
		&:after {
			display: none;
		}
	}
}

/**
 * Breadcrumbs link
 */
.c-breadcrumbs__link {
	margin: 0 0.5rem;

	/**
	 * Breadcrumbs link within first breadcrumb item
	 */
	.c-breadcrumbs__item:first-child & {
		margin-left: 0;
	}
}
