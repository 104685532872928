/*------------------------------------*\
    #HEADINGS
\*------------------------------------*/

/**
 * Heading 1 base styles
 */
h1 {
    font-size: $font-size-xl;
    font-weight: $font-weight-bold;
    line-height: $line-height-compact;
    word-wrap: break-word;

    @media all and (min-width: $bp-med) {
        font-size: $font-size-xxl;
    }
}

/**
 * Heading 2 base styles
 */
h2 {
    font-size: $font-size-large;
    font-weight: $font-weight-bold;
    line-height: $line-height-medium;
    letter-spacing: -0.02rem;
    margin-bottom: 0.5rem;
    word-wrap: break-word;
}

/**
 * Heading 3 base styles
 */
h3 {
    font-size: $font-size-med-2;
    font-weight: $font-weight-bold;
    line-height: $line-height-comfortable;
    display: block;
    margin-bottom: 0.5rem !important;
    word-wrap: break-word;
}

/**
 * Small heading
 */
.heading--sm {
    font-family: $font-family-primary;
    font-weight: $font-weight-bold;
    font-size: $font-size-sm;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: $line-height-large;
    margin-bottom: 0.85rem;
    position: relative;
}

/**
 * Small heading with underline
 */
.heading--special-sm {
    font-family: $font-family-primary;
    font-weight: $font-weight-bold;
    font-size: $font-size-sm;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: $line-height-large;
    margin-bottom: 0.85rem;
    @include headingUnderline();
}
