.project-container {
    text-align: left;
    margin: 0 auto;
    
    @media screen and (min-width: $bp-large) {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .project {
        border-radius: $border-radius-big;
        padding: $pad-normal;
        position: relative;

        @media screen and (min-width: $bp-large) {
            flex: 0 0 50%;
        }

        &--has-casestudy {
            .project__featured-image {
                transform: scale(1) translate(-50%, -50%);
                left: 50%;
                top: 50%;
                transform-origin: center;
                position: absolute;
                transition: transform $anim-quick $anim-ease;
            }

            .project__link {
                transition: opacity $anim-quick $anim-ease;
            }

            &:hover,
            &:focus {
                .project__featured-image {
                    transform: scale(1.25) translate(-40%, -40%);
                }
                .project__link {
                    @media screen and (min-width: $bp-med) {
                        opacity: 1;
                    }
                }
            }
        }

        &__featured-image-container {
            border-radius: $border-radius-big;
            border: 1px solid $color-gray-light;
            position: relative;

            &:after {
                content: '';
                display: block;
                padding-bottom: 50%;
            }
        }

        &__featured-image {
            display: block;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            position: absolute;
        }

        &__featured-image--cropped {
            width: 100%;
            left: 60%;
            bottom: -20%;
        }

        &__link {
            display: block;
            position: absolute;
            height: 2.5rem;
            width: 2.5rem;
            right: 1rem;
            bottom: 1rem;
            padding: $pad-medium $pad-normal;
            border-radius: 100%;
            font-size: $font-size-sm;
            letter-spacing: 1px;
            text-transform: uppercase;
            color: $color-white;
            background: rgba($color-white, 0.1);
            opacity: 0;

            > svg {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }

            @media screen and (max-width: $bp-med) {
                margin-left: -$pad-small;
            }

            &::after {
                border: none;
            }

            &:focus {
                @include focus-outline();
            }

            &:focus:not(:focus-visible) {
                box-shadow: none;
            }

            &:focus-visible {
                @include focus-outline();
            }
        }

        &__link-divider {
            color: $link-icon-color;
            padding: $pad-small;
        }

        &__link-icon {
            color: $link-icon-color;
            font-size: $font-size-sm;
        }

        &__text-container {
            padding: $pad-comfortable 0 0;
            border-radius: $border-radius;
            display: block;

            @media screen and (min-width: $bp-med) {
                padding: $pad-comfortable 0;
            }
        }

        &__header {
            margin: $pad-medium 0;

            @media screen and (min-width: $bp-xxxl) {
                display: flex;
                justify-content: space-between;
                align-items: baseline;
            }
        }

        &__title {
            font-size: $font-size-med-2;
            margin: 0 0 $pad-compact;

            @media screen and (min-width: $bp-xxxl) {
                margin: 0 $pad-comfortable 0 0;
            }
        }

        &__years {
            position: absolute;
            top: 0;
            right: 0;
            background: $color-utility-success-light;
            border-radius: $border-radius;
            color: $color-utility-success;
            padding: $pad-small $pad-medium;
            font-size: $font-size-sm;
            font-weight: $font-weight-semibold;
            margin: 0;

            @media screen and (max-width: $bp-med) {
                font-size: $font-size-tiny;
            }
        }

        &__excerpt p {
            color: $color-gray-medium;
            font-family: $font-family-primary;
            font-weight: $font-weight-normal;
            font-size: $font-size-med-2;
            margin: 0 0 $pad-normal;
            line-height: $line-height-medium;

            @media screen and (min-width: $bp-med) {
                margin: 0;
            }
        }
    }

    .cta {
        max-width: $l-max-width;
        margin: $pad-large auto 0;
    }
}
