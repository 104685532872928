/*------------------------------------*\
    #LOGO
\*------------------------------------*/

/**
 * Branding image or text of the site
 */
.c-logo {

	@media all and (min-width: $bp-xl) {
		margin: 0;
	}

	/**
	 * Logo within vertical header
	 */
	.c-header--vertical & {
		@media all and (min-width: $bp-xl) {
			margin: 1rem 0;
		}
	}
}

/**
 * Logo link
 */
.c-logo__link {
	display: block;
}


/**
 * Logo img
 */
.c-logo__img {
	display: block;
	max-width: 15rem;
	height: auto;

	/**
	 * Logo within vertical header
	 */
	.c-header--vertical & {
		@media all and (min-width: $bp-xl) {
			max-width: 15.75rem;
		}
	}
}
