.p-about {
    display: flex;
    flex-direction: column;

    .content {
        max-width: $l-max-width;
    }

    .intro {
        &__blurb {
            font-size: $font-size-large;
            font-weight: $font-weight-bold;

            @media screen and (min-width: $bp-med) {
                font-size: $font-size-large-2;
            }
        }

        &__inner {
            @media all and (min-width: $bp-med) {
                display: flex;
                justify-content: space-between;
            }
        }

        &__content {
            @media all and (min-width: $bp-xl) {
                margin-bottom: $pad-large;
            }
        }

        &__figure-container {
            position: relative;
        }

        &__image-container {
            width: 100%;
            height: 0;
            position: relative;
            padding-bottom: 100%;
            background-color: $color-gray;
            border-radius: $border-radius-big;
            overflow: hidden;
        }

        &__image {
            width: 100%;
            position: absolute;
        }

        &__image-caption {
            z-index: 1;
            position: absolute;
            bottom: $pad-normal;
            right: $pad-normal;
            color: $color-white;
            text-shadow: 0 0 3px $color-black;

            p {
                font-size: $font-size-tiny;
                margin-bottom: 0;
            }

            a {
                color: $color-white;
                transition: opacity 0.35s;

                &::after {
                    border-color: $color-white;
                }

                &:hover {
                    opacity: 0.75;
                }
            }
        }
    }
}
