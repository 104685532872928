.social-icons {
    display: flex;

    @media all and (max-width: $bp-med) {
        justify-content: center;
    }

    &__link {
        color: $social-icon-fill;
        transition: fill 0.15s ease-in;
        padding: $pad-small;
        border-radius: $border-radius-big;
        transition: fill 0.15s;

        &::after {
            display: none;
        }

        &:hover,
        &:focus {
            color: $link-color;
        }

        &:focus {
            @include focus-outline();
        }

        &:focus:not(:focus-visible) {
            color: $social-icon-fill;
            box-shadow: none;
        }

        &:focus-visible {
            @include focus-outline();
        }
    }

    &__icon {
        font-size: $font-size-large;
        margin-left: 0;
        text-indent: initial;
        margin-bottom: 0;
        margin: $pad-compact;

        &::before {
            display: none !important;
        }

        @media all and (max-width: $bp-med) {
            font-size: $font-size-large-2;
            margin: 0.5rem;
        }
    }
}
