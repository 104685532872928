.intro {
    &__inner {
        position: relative;

        @media all and (max-width: $bp-med) {
            display: flex;
            flex-direction: column;
        }
    }

    &__content {
        order: 3;

        @media all and (min-width: $bp-med) {
            order: auto;
            width: 80%;
            position: relative;
            z-index: 1;
        }

        @media all and (min-width: $bp-large) {
            width: 50%;
        }
    }

    &__figure-container {
        order: 2;
        margin-bottom: $pad-comfortable;
        position: relative;

        @media all and (min-width: $bp-med) {
            order: auto;
            position: absolute;
            width: 45%;
            top: 0;
            right: 0;
        }
    }

    &__heading {
        order: 1;

        @media all and (min-width: $bp-large) {
            order: auto;
            flex: 0 1 100%;
        }
    }

    &__blurb {
        font-family: $font-family-primary;
        font-weight: $font-weight-bold;
        font-size: $font-size-large;
        line-height: 1.35;

        @media screen and (min-width: $bp-med) {
            font-size: $font-size-large-2;
        }

        @media screen and (min-width: $bp-large-2) {
            font-size: $font-size-xxl;
        }
    }

    &__link {
        margin-top: 1rem;

        @media screen and (min-width: $bp-med) {
            margin-top: 2rem;
        }
    }

    &__link-icon {
        color: $color-gray;
    }
}
