/*------------------------------------*\
    #LINKS
\*------------------------------------*/

/**
 * Link base styles
 */
a {
    color: $link-color;
    font-weight: $font-weight-semibold;
    text-decoration: none;
    outline: 0;
    transition: color $anim-med ease-out, background $anim-quickest ease-out, box-shadow $anim-quick ease-out;
    position: relative;
  
    &::after {
        content: "";
        height: 1px;
        border-bottom: 1px dotted $link-underline-color;
        border-color: $link-underline-color;
        position: absolute;
        right: 0;
        bottom: -0.2rem;
        left: 0;
        width: 0;
        transform: scaleX(0);
        transform-origin: center;
        transition: border-color $anim-med ease-in,
                    color $anim-med ease-in,
                    transform $anim-fast ease-in;
    }

    &:hover, &:focus {
        color: $link-color-hover;

        &::after {
          border-color: $link-underline-color-hover;
        }
    }

    &:focus {
        @include focus-outline();
        border-radius: $border-radius-big;
    }

    &:focus:not(:focus-visible) {
        color: $link-color;
        box-shadow: none;

        &::after {
          border-color: transparent;
        }
    }

    &:focus-visible {
        color: $color-black;
        border-radius: $border-radius-big;
        @include focus-outline();

        &::after {
          border-color: $link-underline-color-hover;
        }
    }
}
