/*------------------------------------*\
    #PROJECT VISUAL
\*------------------------------------*/

.project-visual {
    line-height: 0;
    border-radius: $border-radius-big;
    overflow: hidden;

    &__image > img {
        width: 100%;
        border-radius: $border-radius-big;
    }

    &__image-caption {
        font-size: $font-size-tiny;
        text-align: center;
        margin-top: $pad-medium;
        p {
            font: inherit;
        }
    }
}
