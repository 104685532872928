/*------------------------------------*\
    #DISPLAY CLASSES
\*------------------------------------*/

/**
 * Display block
 * 1) Add display block by overriding
 */
.u-display-block {
    display: block !important;
}

/**
 * Margin Bottom Double
 * 1) Adds double the base unit of margin
 *    to the bottom of an element
 */
.u-margin-bottom-double {
    margin-bottom: 2rem !important;
}
