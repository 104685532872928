/*------------------------------------*\
    #VISIBILITY CLASSES
\*------------------------------------*/

/**
 * Is Hidden
 * 1) Completely remove from the flow and screen readers.
 */

.u-is-hidden,
[hidden] {
    display: none !important;
    visibility: hidden !important;
}

/**
 * Is Visibly Hidden
 * 1) Completely remove from the flow but leave available to screen readers.
 */
.u-is-vishidden {
    position: absolute !important;
    overflow: hidden;
    width: 1px;
    height: 1px;
    padding: 0;
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
}
