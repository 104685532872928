/*------------------------------------*\
    $FOOTER
\*------------------------------------*/

/**
 * 1) Global block at the bottom of each page that contains a navigation and other information
 */
.footer {
    text-align: center;
    display: flex;
    flex-direction: column;
    margin: auto $pad-normal $pad-large-2;

    @media all and (min-width: $bp-med) {
        padding: $pad-normal $pad-large-2;
    }

    &__social-block {
        margin: 0 auto;
    }

    &__smallprint {
        font-family: $font-family-secondary;
        margin-top: $pad-comfortable;
        color: $color-gray-medium;
    }
}
