.p-work {
    display: flex;
    flex-direction: column;
    text-align: center;
    
    .content {
        padding: $pad-large $pad-small 0;

        @media screen and (min-width: $bp-med) {
            padding: $pad-large-2 $pad-normal 0;
        }

        @media screen and (min-width: $bp-xl) {
            padding: $pad-large-2 $pad-normal 0;
        }
    }

    .intro {
        margin: 0 auto $pad-normal;
        max-width: $l-max-width-narrow;

        @media screen and (min-width: $bp-med) {
            margin: 0 auto $pad-large-2;
            max-width: none;
        }

        &__blurb {
            font-size: 5.45vw;
            margin: 0 auto;
            opacity: 0;
            background: linear-gradient(
                to right,
                #DD9787,
                #8A4FFF,
                #E0D68A
            );
            background-size: 400% 400%;
            background-clip: text;
            -webkit-background-clip: text;
            text-fill-color: text;
            -webkit-text-fill-color: transparent;
            animation: gradientAnimation 15s ease infinite;

            @media screen and (max-width: $bp-med) {
                font-size: $font-size-large-2;
            }

            @media screen and (min-width: $bp-med) {
                max-width: 72vw;
            }

            p {
                font-weight: $font-weight-bold;
                line-height: 1.2;
                font-size: inherit;
                font-style: inherit;
                font-family: inherit;
                -webkit-background-clip: inherit;
                -webkit-text-fill-color: inherit;
                animation: none;
            }

            a {
                font-weight: inherit;
                color: $color-black;
                -webkit-background-clip: initial;
                -webkit-text-fill-color: initial;
                transition: color 0.2s ease-in;
                animation: none;
                padding-bottom: 0.5rem;
                text-shadow:
                    -6px -6px $body-background,
                    -6px 6px $body-background,
                    6px -6px $body-background,
                    6px 6px $body-background;
                background-size: 1px 1em;
                box-shadow:
                  inset 0 -0.175em $body-background,
                  inset 0 -0.2em $color-black;
                display: inline;

                &:hover, 
                &:focus {
                    color: $color-gray-dark;
                }

                &::after {
                    display: none;
                }
            }
        }

        &__desc {
            margin: 0 auto;

            @media screen and (min-width: $bp-med) {
                font-size: $font-size-large;
                line-height: 1.5;
                max-width: $l-max-width-narrow;
            }
        }
    }
}
