/*------------------------------------*\
    #FORMS
\*------------------------------------*/

/**
 * 1) Form element base styles
 */

 /**
  * Input placeholder text base styles
  */
::-webkit-input-placeholder {
   color: $color-gray-dark;
}

::-moz-placeholder {  /* Firefox 19+ */
   color: $color-gray-dark;
}

:-ms-input-placeholder {
   color: $color-gray-dark;
}

/**
 * Fieldset base styles
 */
fieldset {
    border: 0;
    padding: 0;
    margin: 0;
}

/**
 * Legend base styles
 */
legend {
    text-transform: lowercase;
    margin-bottom: 0.25em;
}

/**
 * Label base styles
 */
label {
    display: block;
    padding-bottom: 0.25rem;
}

/**
 * Add font size 100% of form element and margin 0 to these elements
 */
button, input, select, textarea {
    font-family: inherit;
    font-size: 100%;
    margin: 0;
}

/**
 * Text area base styles
 */
textarea {
    resize: none;
}

/**
 * Input  and text area base styles
 */
input, textarea {
    width: 100%;
    border: 1px solid $color-gray-dark;
    padding: 0.5rem 0.65rem;
}

/**
 * Remove webkit appearance styles from these elements
 */
input[type=text], input[type=search], input[type=url], input[type=number], textarea {
    -webkit-appearance: none;
}

/**
 * Checkbox and radio button base styles
 */
input[type="checkbox"],
input[type="radio"] {
    width: auto;
    margin-right: 0.3em;
}

/**
 * Search input base styles
 */
input[type="search"] {
    -webkit-appearance: none;
    border-radius: 0;
}

/**
 * Remove webkit appearance styles from search input cancel and decoration
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

/**
 * Select
 * 1) Remove default styling
 */
select {
    display: block;
    font-size: $font-size-sm-2;
    height: 2rem;
    width: 100%;
    border: 1px solid $color-gray-light;
    padding: 0.3rem;
    border-radius: $border-radius;
    background: $color-white;
    color: $color-gray-darkest;
    -moz-appearance: none;
    -webkit-appearance: none;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMTIgMTEiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDEyIDExOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgZmlsbD0iIzgwODA4MCI+PHBhdGggZD0iTTIsNGw0LDRsNC00SDJ6Ii8+PC9zdmc+);
    background-position: calc(100% - 0.3rem) 50%;
    background-size: 16px 16px;
    background-repeat: no-repeat;

    &:focus {
        outline: 0;
        border-color: $color-gray-dark;
    }

    /**
     * 1) Remove IE select arrow
     */
    &::-ms-expand { /* 1 */
        display: none;
    }

    /**
     * 1) Style IE value selector
     */
    &::-ms-value { /* 1 */
        background: transparent;
        color: $color-gray-dark;
    }
}
