/*------------------------------------*\
    #BUTTONS
\*------------------------------------*/

/**
 * Button and submit inputs reset
 * 1) These should be styled using c-btn
 */
button, input[type=submit] {
	cursor: pointer;

	&:focus {
		outline: 1px solid $color-gray-dark;
	}

    &:focus:not(:focus-visible) {
        outline: none;
    }

    &:focus-visible {
        outline: 1px solid $color-gray-dark;
    }
}
