/*------------------------------------*\
    #TEXT
\*------------------------------------*/

/**
 * Paragraph base styles
 */
p {
    font: $body-font;
	margin-bottom: $space;
}

/**
 * Blockquote base styles
 */
blockquote {
    font-style: normal;
    font-weight: $font-weight-light;
    font-size: $font-size-med-2;
    border-left: 3px solid $color-muted-lilac;
    color: $color-gray-dark;
	padding-left: 1rem;
	margin-bottom: $space;
}

cite {
    font-style: normal;
    font-size: $font-size-sm;
}

/**
 * Horizontal rule base styles
 */
hr {
	border: 0;
    height: 1px;
    background: $color-gray-light;
    margin: 1rem 0;
}

/**
 * Selection styles
 */
::-moz-selection {
    color: black;
    background: rgba($color-peach, 0.75); /* Gecko Browsers */
}
::selection {
    color: black;
    -webkit-text-fill-color: black;
    background: rgba($color-peach, 0.75); /* WebKit/Blink Browsers */
}

/**
 * Code base styles
 */
code {
	display: inline-block;
	background: $color-gray-lightest;
	border: 1px solid $color-gray-light;
	padding: $pad-small $pad-medium;
    line-height: $line-height-medium;
    font-size: $font-size-sm;
}

/**
 * Preformatted text base styles
 */
pre {
	background: $color-gray-lightest;
	border: 1px solid $color-gray-light;
	font-size: $font-size-med;
	padding: $pad-normal;
    overflow-x: auto;

	/**
	 * Remove border from code within preformatted text block
	 */
	code {
		border: 0;
	}
}

/**
 * Code with languages associated with them
 * 1) Override Prism sysles for code blocks with language
 */
code[class*="language-"],
pre[class*="language-"] {
    font-family:monospace !important;
}
