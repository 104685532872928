/*------------------------------------*\
    #HERO
\*------------------------------------*/

/**
 * 1) Block containing headline, description text, that serves as a primary content area
 */
.hero {
    @media all and (min-width: $bp-med) {
        margin-top: -($pad-large-2);
        margin-bottom: $pad-large-2;
    }

    &__inner {
        display: flex;
        flex-direction: column;
        position: relative;
    }

    &__image-block {
        width: 100%;
        position: relative;

        @media all and (min-width: $bp-med) {
            min-height: 600px;
            height: 60vh;
        }
    }

    &__image-container {
        overflow: hidden;
        position: relative;
        width: 100%;
        height: 100%;

        &::after {
            content: '';
            display: block;
            padding-bottom: 60%;
        }
    }

    &__image {
        display: block;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        position: absolute;

        @media all and (min-width: $bp-small-2) {
            margin: 0;
        }
    }

    &__image--cropped {
        width: 100%;
        left: 60%;
        bottom: -20%;
    }

    &__text-block {
        padding: $pad-large 0;
        max-width: $l-max-width;
        border-bottom: 1px solid $h1-border-color;
        margin: 0 auto;

        @media screen and (max-width: $bp-large-2) {
            padding: $pad-medium 0;
        }
    }

    &__text {
        @media screen and (min-width: $bp-large) {
            display: grid;
            grid-template-columns: 1.5fr 1fr 1fr;
            column-gap: $pad-large-2;
            align-items: start;
        }
    }

    &__title-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__title {
        font-weight: $font-weight-bold;
        font-size: $font-size-xxl;
        margin-bottom: 0.75rem;

        @media screen and (max-width: $bp-med) {
            font-size: $font-size-large-2;
            margin-bottom: 0.5rem;
        }
    }

    &__project-url-container {
        font-size: $font-size-med-2;
        margin-left: $pad-medium;
        display: inline-block;
    }

    &__project-link {
        color: $color-gray-medium;
        padding: $pad-medium;
        border-radius: 100%;

        &:hover {
            background: $color-gray-light;
        }

        &::after {
            display: none;
        }
    }

    &__subtitle {
        font-family: $font-family-primary;
        font-weight: $font-weight-normal;
        font-size: $font-size-large;
        margin-bottom: $pad-medium;
        line-height: 1.4;
        color: $color-gray-medium;
        margin-bottom: 0;

        @media screen and (max-width: $bp-med) {
            font-size: $font-size-large;
            margin-bottom: $pad-normal;
        }
    }

    &__blurb {
        font-weight: $font-weight-normal;
        font-size: $font-size-sm-2;
        margin-bottom: $pad-normal;

        p {
            font-size: inherit;
        }

        @media screen and (min-width: $bp-large) {
            grid-column: 1 / span 2;
        }
    }

    &__roles,
    &__organization  {
        margin-bottom: $pad-normal;
    }

    &__roles-title,
    &__organization-title {
        font-size: $font-size-sm;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        letter-spacing: 1px;
    }

    &__roles-list {
        font-family: $font-family-secondary;
        font-size: $font-size-sm;
        display: block;
    }

    &__roles-title {
        display: inline;
    }

    &__organization-title {
        margin-right: 0.2rem;
        margin-bottom: 0 !important;
    }

    &__organization-name {
        font-family: $font-family-secondary;
        font-size: $font-size-sm;
    }
}
