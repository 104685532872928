/*------------------------------------*\
    #PAGE HEADER
\*------------------------------------*/
/**
 * 1) Container that consists of of a page header title and description
 */

/**
 * Page header title
 */
.c-page-header__title {
    margin-bottom: 0.2rem;
}

/**
 * Page description
 */
.c-page-header__desc {
    margin-bottom: 2rem;
    font-size: $font-size-med-2;
    color: $color-gray;
    max-width: $l-max-width-narrow;
}
