/*------------------------------------*\
    #BUTTONS
\*------------------------------------*/

/**
 * 1) Button or link that has functionality to it
 * 2) Remove box-shadow that's globally applied to links
 */
.c-btn {
    font-size: $font-size-sm;
    font-family: $font-family-primary;
    display: inline-block;
    background: $button-background;
    border: 2px solid transparent;
    border-radius: $border-radius;
    cursor: pointer;
    color: $button-color;
    line-height: $line-height-compact;
    font-weight: $font-weight-bold;
    padding: $pad-medium $pad-normal;
    box-shadow: none; /* 2 */
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: all $anim-quick ease-out;
    position: relative;

    @media all and (max-width: $bp-small-3) {
        width: 100%;
    }

    &::after {
        display: none !important;
    }

    &:hover,
    &:focus {
        background: $button-background-hover;
        color: $button-color-hover;
    }

    &:active {
        background: darken($button-background-hover, 0.1);
        color: $button-color-hover;
    }

    &:focus {
        @include focus-outline();
    }

    &:focus:not(:focus-visible) {
        box-shadow: none;
    }

    &:focus-visible {
        @include focus-outline();
    }

    /**
     * Button within button group
     */
    .c-btn-group & {
        margin-right: 0.5rem;

        &:last-child {
            margin-right: 0;
        }
    }

    /**
     * Button within button group
     */
    .c-btn-group & {
        margin-right: 0.5rem;

        /**
         * Remove right margin on last button group button
         */
        &:last-child {
            margin-right: 0;
        }
    }

    /**
     * Button within button group
     */
    .c-btn-group--responsive & {
        margin-right: 0;
        margin-bottom: 0.5rem;

        @media all and (min-width: $bp-small) {
            margin-right: 0.5rem;
            margin-bottom: 0;

            /**
             * Remove right margin on last responsive button group button
             */
            &:last-child {
                margin-right: 0;
            }
        }
    }
}

/**
 * Small button
 */
.c-btn--small {
    padding: 0.375rem 0.75rem;
}

/*
 * Inner container of the buton
 * Allows for display flex on a button
 */
.c-btn__inner {
    display: flex;
    align-items: center;
    justify-content: center;
}

/*
 * Button icon
 */
.c-btn__icon {
    width: 16px;
    height: 16px;
    fill: $color-gray-dark;
    position: relative;
    transition: fill $anim-quick $anim-ease;

    /*
     * Button icon within button hover
     */
    .c-btn:hover &,
    .c-btn:focus & {
        fill: $color-gray-darkest;
    }

    .c-btn:focus:not(:focus-visible) & {
        box-shadow: none;
    }

    .c-btn:focus-visible & {
        @include focus-outline();
    }

    /*
     * Button icon within active button
     */
    .c-btn.is-active & {
        fill: $color-black;
    }
}

/*------------------------------------*\
    #TEXT BUTTON
\*------------------------------------*/

/**
 * 1) Button that is text only and doesn't contain a border or fill.
 */
.c-btn--text {
    background: none;
    border: 0;
    padding: 0;
    cursor: pointer;
    border-radius: $border-radius;
    transition: background $anim-quick $anim-ease;
    position: relative;
    outline: none;

    &:focus {
        @include focus-outline();
    }
}
