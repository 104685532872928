.about-block {
    padding-top: $pad-normal;
    text-align: center;
    width: 100%;
    max-width: $l-max-width;
    margin: 0 auto;

    @media all and (min-width: $bp-med) {
        display: flex;
        justify-content: space-between;
        text-align: left;
    }

    &__block {
        @media all and (min-width: $bp-med) {
            margin-right: $pad-large;
            flex: 0 0 30%;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &__intro {
        font-size: $font-size-large;
        font-style: italic;
        text-align: center;
        align-items: center;
        justify-content: stretch;
        padding: $pad-normal $pad-large;
        @include backgroundPattern();
        
        @media all and (min-width: $bp-med) {
            margin-right: $pad-large;
            display: flex;
        }
    }

    &__title {
        margin-bottom: $pad-normal;
        @include headingUnderline();

        @media all and (max-width: $bp-med) {
            display: inline-block;
            margin-top: $pad-comfortable;
        }
    }

    &__subtitle {
        font-size: $font-size-med-2;
        font-style: italic;
        font-weight: $font-weight-light;
    }

    &__list {
        margin: 0;
    }

    &__list-item {
        margin-left: 0;
        text-indent: initial;
        margin-bottom: 0;
        
        &::before {
            display: none!important;
        }
    }

    &__attr {
        color: inherit;
    }

    &__desc {
        font-family: $font-family-secondary;
        font-size: $font-size-sm-2;
        margin-bottom: $pad-medium;
    }

    .insta-feed {
        display: flex;

        @media all and (max-width: $bp-med) {
            max-width: 20rem;
            margin: 0 auto;
        }
        
        &__list-item {
            flex: 0 0 48%;
            margin-left: 0;
            text-indent: initial;
            margin-bottom: 0;
            
            &::before {
                display: none!important;
            }

            &:first-child {
                margin-right: 2%;
            }
        }

        &__link {
            &::after {
                display: none;
            }
        }

        &__image {
            border-radius: $border-radius;
            transition: transform 0.35s;

            &:hover {
                transform: scale(1.025);
                transition: transform 0.35s;
                z-index: 1;
            }
        }
    }
}