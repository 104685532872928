/*------------------------------------*\
    #MEDIA
\*------------------------------------*/

/**
 * Responsive image styling
 * 1) Allows for images to flex with varying screen size
 */
img {
	max-width: 100%;
	height: auto;
}

figcaption {
    font-size: $font-size-sm;
    line-height: $line-height-medium;
}

.lazy {
    opacity: 0;
    transition: opacity 0.25s;
}

.lazy.loaded {
    opacity: 1;
}

.desktop-only {
    display: none;

    @media all and (min-width: $bp-med) {
        display: block;
    }
}

.mobile-only {
    display: none;

    @media all and (max-width: $bp-med) {
        display: block;
    }
}