/*------------------------------------*\
    #PROJECT GRID
\*------------------------------------*/

.project-grid {
    line-height: 0;
    border-radius: $border-radius-big;
    overflow: hidden;

    &__image-container {
        width: 100%;
        border-radius: $border-radius-big;
        overflow: hidden;
        margin-left: 0;
        text-indent: initial;
        margin-bottom: 0;
        line-height: 0;

        &::before {
            display: none !important;
        }
    }

    &__image > img {
        width: 100%;
        border-radius: $border-radius-big;
    }

    &__image-caption {
        font-size: $font-size-tiny;
        text-align: center;
        margin-top: -0.75rem;
    }
}

.section__visual-container {
    .project-grid {
        &__images {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        &__image-container {
            margin-bottom: $pad-normal;
        }
    }

    &--stacked {
        .project-grid {
            @media screen and (min-width: $bp-small-3) {
                &__image-container {
                    flex: 0 0 100%;
                }
            }
        }
    }

    &--side-by-side {
        .project-grid {
            @media screen and (min-width: $bp-small-3) {
                &__image-container {
                    flex: 0 0 49%;
                }
            }
        }
    }

    &--big-small-small {
        .project-grid {
            @media screen and (min-width: $bp-small-3) {
                &__image-container {
                    flex: 0 0 49%;
                    margin-bottom: 0;
                }

                &__image-container:first-child {
                    flex: 0 0 100%;
                    margin-bottom: $pad-normal;
                }
            }
        }
    }

    &--big-small {
        .project-grid {
            @media screen and (min-width: $bp-small-3) {
                &__image-container {
                    flex: 0 0 27.75%;
                    margin-bottom: 0;
                }

                &__image-container:first-child {
                    flex: 0 0 71%;
                    margin-bottom: $pad-normal;
                }
            }
        }
    }

    &--small-small-big {
        .project-grid {
            @media screen and (min-width: $bp-small-3) {
                &__image-container {
                    flex: 0 0 49%;
                }

                &__image-container:last-child {
                    flex: 0 0 100%;
                    margin-bottom: 0;
                }
            }
        }
    }

    &--small-big {
        .project-grid {
            @media screen and (min-width: $bp-small-3) {
                &__image-container {
                    flex: 0 0 27.75%;
                    margin-bottom: 0;
                }

                &__image-container:last-child {
                    flex: 0 0 71%;
                    margin-bottom: $pad-normal;
                }
            }
        }
    }
}
