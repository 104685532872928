.cta {
    position: relative;
    margin-bottom: $pad-comfortable;
    padding: $pad-large;

    &::after {
        content: '';
        background-image: url(../assets/images/bank-note.svg);
        background-size: 5rem;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -2;
        opacity: 0.45;
    }

    &::before {
        content: '';
        background: rgba($color-muted-lilac, 0.15);
        background-size: 100%;
        position: absolute;
        top: 10px;
        right: 10px;
        bottom: -10px;
        left: -10px;
        z-index: -3;
    }

    &__inner {
        z-index: 2;

        @media screen and (min-width: $bp-med) {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    &__title {
        font-family: $font-family-primary;
        font-size: $font-size-large;
        font-weight: $font-weight-normal;
        margin-bottom: $pad-compact;

        @media screen and (max-width: $bp-med) {
            max-width: 25rem;
            margin: 0 auto $pad-small;
            text-align: center;
        }
    }

    &__blurb {
        margin-bottom: 0;

        @media screen and (max-width: $bp-med) {
            max-width: 20rem;
            margin: 0 auto $pad-normal;
            text-align: center;
        }

        p {
            font-family: $font-family-primary;
            line-height: 1.65;
            font-size: $font-size-med-2;
            margin-bottom: 0;
        }
    }

    &__btn {
        width: 100%;
        background: $color-muted-lilac-dark;
        color: $color-gray-lightest;

        &:hover,
        &:focus {
            background: darken($color-muted-lilac-dark, 25%);
            color: $color-gray-lightest;
        }

        &:active {
            background: darken($color-muted-lilac-dark, 45%);
            color: $color-gray-lightest;
        }

        @media screen and (min-width: $bp-med) {
            width: auto;
            flex: 1 0 auto;
            margin-left: $pad-normal;
        }
    }
}
