/*------------------------------------*\
    #TABLES
\*------------------------------------*/

/**
 * Table
 */
table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
}

/**
 * Table header cell
 */
th {
    text-align: left;
}

/**
 * Table row
 */
tr {
    vertical-align: top;
}
